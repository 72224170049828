import classnames from 'classnames'
import { cloneDeep, includes, isEqual, isNil, omit } from 'lodash'
import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { unstable_usePrompt, useBeforeUnload } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button, { ButtonFill, ButtonSize } from '@athena/component/atom/button'
import Checkbox from '@athena/component/atom/checkbox'
import Combobox, { Option } from '@athena/component/atom/combobox'
import Icon, { IconName } from '@athena/component/atom/icon'
import Loading from '@athena/component/atom/loading'
import Textarea from '@athena/component/atom/textarea'
import Input from '@athena/component/molecule/input'
import Modal from '@athena/component/molecule/modal'
import Response from '@athena/core/client/response'
import Promotion from '@athena/core/model/promotion'
import RevueObjective, { Rating, RevueObjectiveTitle } from '@athena/core/model/revue-objective'
import RevueObjectiveGoal from '@athena/core/model/revue-objective-goal'

import { useIdentity } from '@apollo/client/hook/auth'
import {
  useAddPromotion,
  usePromotionByObjectiveId,
  useRemovePromotion,
  useUpdatePromotion,
} from '@apollo/client/hook/promotion'
import { useRemoveRevueObjective, useRevueObjectiveById, useUpdateRevueObjective } from '@apollo/client/hook/revue'
import { useEmployeeReference } from '@artemis/client/hook'
import ConfirmationDialog from '@future-view/frontend/component/confirm-dialog'
import { validationRules } from '@future-view/frontend/component/performance-objective-detail/util'
import PerformanceObjectiveGoalList from '@future-view/frontend/component/performance-objective-goal'
import {
  deleteLocal,
  getDescription,
  getLocal,
  getTitle,
  setLocal,
} from '@future-view/frontend/page/revue-dashboard/util'
import { formatDate, generateRevueDD } from '@future-view/frontend/util'
import Logger from '@future-view/frontend/util/log'

import Props from './interface'
import Style from './style.module.scss'

const cx = classnames.bind(Style)
const CURRENT_ID = 'current'
const SELF_RATING_ID = 'self_rating'
const SELF_ASSESSMENT_ID = 'self_assessment'
const MANAGER_RATING_ID = 'manager_rating'
const MANAGER_ASSESSMENT_ID = 'manager_assessment'
const SPONSOR1_ID = 'sponsor1'
const SPONSOR2_ID = 'sponsor2'
const CRAFT_ID = 'craft'
const METHOD_ID = 'method'
const LEADERSHIP_ID = 'leadership'
const GOALS_ID = 'goals'

const log = Logger.child({ module: 'component/performance-objective-detail' })
const managerTooltipHTML = (
  <>
    <div className={Style.tooltipWrapper}>
      <div className={Style.label}>5: Stellar</div>
      <div>You consistently performed at a level above your current level, and sometimes even beyond that.</div>
      <div className={Style.label}>4: Exceeds</div>
      <div>You spent significant time performing at a level above your current level.</div>
      <div className={Style.label}>3: Meets</div>
      <div>You have met expectations. You are performing solidly at your current level.</div>
      <div className={Style.label}>2: Below</div>
      <div>
        You spent significant time performing at a level below your current level. Or you have consistently exhibited
        behavior that needs improvement.
      </div>
      <div className={Style.label}>1: Poor</div>
      <div>
        You consistently performed at a level below your current level, or you have consistently exhibited behavior that
        is seriously detrimental to your team environment, the client, or Lab49 as a whole.
      </div>
    </div>
  </>
)

const promotionTooltipHTML = (
  <div>
    <b>Instructions:</b> Provide two references at or above the desired level at your direct report who is supporting
    nomination for promotion. Establish your direct report&apos;s qualifications by showcasing their alignment with the
    criteria for the next level within the career framework&apos;s dimensions. Open the{' '}
    <a
      className={Style.href}
      href="https://iontradingcom.sharepoint.com/teams/Lab49TalentProjects/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FLab49TalentProjects%2FShared%20Documents%2FShared%5FPeople%20Manager%20resources%2FPerformance%20management%2FCareer%20Framework%5FOctober2022%2Epdf&parent=%2Fteams%2FLab49TalentProjects%2FShared%20Documents%2FShared%5FPeople%20Manager%20resources%2FPerformance%20management&p=true&ga=1"
      rel="noreferrer"
      target="_blank"
    >
      Career Framework
    </a>{' '}
    and provide examples of achievements for the below dimensions
  </div>
)

const RatingNumbers: Record<string, number> = {
  'N/A': 0,
  Poor: 1,
  Below: 2,
  Meets: 3,
  Exceeds: 4,
  Stellar: 5,
}

const getCurrentYear = () => {
  return DateTime.now().get('year')
}

const PerformanceObjectiveDetail: React.FC<Props> = ({
  'data-test': dataTest = 'PerformanceObjectiveDetail',
  id,
  activationDate: initialActivationDate,
  handleClose,
  isManager = false,
}) => {
  const calcDaysRemaining = () => {
    const d = DateTime.fromJSDate(new Date())
    const now = DateTime.now().endOf('year')
    return Math.floor(now.diff(d, ['days']).days)
  }
  const [activationDate, setActivationDate] = useState(initialActivationDate || new Date())
  useEffect(() => {
    if (initialActivationDate) {
      setActivationDate(initialActivationDate)
    }
  }, [initialActivationDate])
  const objectiveDetailData = useRevueObjectiveById(id)
  const removeRevueObjective = useRemoveRevueObjective()
  const updateRevueObjective = useUpdateRevueObjective()
  const addPromotion = useAddPromotion()
  const updatePromotion = useUpdatePromotion()
  const removePromotion = useRemovePromotion()
  const promotion = usePromotionByObjectiveId(objectiveDetailData.data?.data?.id ?? '', isManager)
  const [objectiveData, setObjectiveData] = useState<RevueObjective>(new RevueObjective(''))
  const [initialObjectiveData, setInitialObjectiveData] = useState<RevueObjective>(new RevueObjective(''))
  const [promotionData, setPromotionData] = useState<Promotion>(new Promotion(''))
  const [initialPromotionData, setInitialPromotionData] = useState<Promotion>(new Promotion(''))
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [isCurrentInvalid, setIsCurrentInvalid] = useState<boolean>(false)
  const [isCurrentDisabled, setIsCurrentDisabled] = useState<boolean>(false)
  const [isSelfRatingInvalid, setIsSelfRatingInvalid] = useState<boolean>(false)
  const [isSelfRatingDisabled, setIsSelfRatingDisabled] = useState<boolean>(false)
  const [isSelfAssessmentInvalid, setIsSelfAssessmentInvalid] = useState<boolean>(false)
  const [isSelfAssessmentDisabled, setIsSelfAssessmentDisabled] = useState<boolean>(false)
  const [isManagerRatingInvalid, setIsManagerRatingInvalid] = useState<boolean>(false)
  const [isManagerRatingDisabled, setIsManagerRatingDisabled] = useState<boolean>(false)
  const [isManagerAssessmentInvalid, setIsManagerAssessmentInvalid] = useState<boolean>(false)
  const [isManagerAssessmentDisabled, setIsManagerAssessmentDisabled] = useState<boolean>(false)
  const [isSponsor1Invalid, setSponsor1Invalid] = useState<boolean>(false)
  const [isSponsor1Disabled, setSponsor1Disabled] = useState<boolean>(false)
  const [isSponsor2Invalid, setSponsor2Invalid] = useState<boolean>(false)
  const [isSponsor2Disabled, setSponsor2Disabled] = useState<boolean>(false)
  const [isCraftInvalid, setCraftInvalid] = useState<boolean>(false)
  const [isCraftDisabled, setCraftDisabled] = useState<boolean>(false)
  const [isMethodInvalid, setMethodInvalid] = useState<boolean>(false)
  const [isMethodDisabled, setMethodDisabled] = useState<boolean>(false)
  const [isLeadershipInvalid, setLeadershipInvalid] = useState<boolean>(false)
  const [isLeadershipDisabled, setLeadershipDisabled] = useState<boolean>(false)
  const [isRevueInvalid, setIsRevueInvalid] = useState<boolean>(false)
  const [isGoalsInvalid, setIsGoalsInvalid] = useState<boolean>(false)
  const [isPromotionDisabled, setPromotionDisabled] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [currentErrorMsg, setCurrentErrorMsg] = useState<string | null>(null)
  const [isAddGoalDisabled, setIsAddGoalDisabled] = useState<boolean>(false)
  const [isObjectiveChanged, setIsObjectiveChanged] = useState<boolean>(false)
  const [isPromotionChanged, setIsPromotionChanged] = useState<boolean>(false)
  const [showSelfTooltip, setShowSelfTooltip] = useState<boolean>(false)
  const [showManagerTooltip, setShowManagerTooltip] = useState<boolean>(false)
  const [showPromotionTooltip, setShowPromotionTooltip] = useState<boolean>(false)
  const [isPromoted, setIsPromoted] = useState<boolean>(false)
  const [isRatingChanged, setIsRatingChanged] = useState<boolean>(false)
  const identity = useIdentity()
  const loggedInUserId = identity?.data?.data?.cid ?? ''
  //TODO outsource this
  const resourceReference = useEmployeeReference()
  const [userFullNameByResourceId, setResourceIdToUserFullNameMap] = useState<Map<string, string>>()
  const resourceOptions = useMemo(
    () =>
      Array.from(userFullNameByResourceId || new Map(), ([userId, userName]) => ({ label: userName, value: userId })),
    [userFullNameByResourceId]
  )
  const isActive = useCallback(() => {
    if (
      new Date(initialObjectiveData.created + '').getFullYear() <= new Date(formatDate(activationDate)).getFullYear() ||
      new Date(objectiveData.created + '').getFullYear() <= new Date(formatDate(activationDate)).getFullYear()
    ) {
      return false
    }
    return true
  }, [activationDate, initialObjectiveData.created, objectiveData.created])
  const NAOption = { label: Rating.NA, value: Rating.NA }
  const ratingOptions = useMemo(() => {
    const optionsWithNA: Option[] = generateRevueDD(Rating)
    const optionsWithoutNA: Option[] = optionsWithNA.filter((option) => option.value !== Rating.NA)
    return includes(initialObjectiveData.title, 'Summary of Accomplishments') ||
      includes(initialObjectiveData.title, 'Sales participation')
      ? optionsWithNA
      : optionsWithoutNA
  }, [initialObjectiveData.title])

  //INITIALIZE SPONSOR OPTIONS
  useEffect(() => {
    if (resourceReference.data) {
      const data = resourceReference.data.data || []
      const userFullNameById: Map<string, string> = data
        .filter((item) => item.isActiveEmployee === 'YES' && item.id !== loggedInUserId)
        .reduce((acc, resource) => {
          acc.set(resource.id, resource.name)
          return acc
        }, new Map<string, string>())

      setResourceIdToUserFullNameMap(userFullNameById)
    }
  }, [loggedInUserId, resourceReference.data])
  //END TODO outsource this

  //INITIALIZE LOCAL STATE
  useEffect(() => {
    if (objectiveDetailData?.data?.data) {
      let objectiveDData = objectiveDetailData?.data?.data
      if (objectiveDData.id) {
        const localData = getLocal(`objective-${objectiveDData.id}`)
        if (!localData) {
          setLocal(objectiveDData, `objective-${objectiveDData.id}`)
          setInitialObjectiveData((prev: RevueObjective) => {
            return Object.assign(cloneDeep(prev), objectiveDData)
          })
        } else {
          if (
            (objectiveDData.updated && new Date(localData.updated) > new Date(objectiveDData.updated)) ||
            (!objectiveDData.updated && localData)
          ) {
            objectiveDData = localData
          } else {
            setLocal(objectiveDData, `objective-${objectiveDData.id}`)
            setInitialObjectiveData((prev: RevueObjective) => {
              return Object.assign(cloneDeep(prev), objectiveDData)
            })
          }
        }

        if (
          objectiveDData.bulk_updated &&
          objectiveDData.updated &&
          new Date(objectiveDData.bulk_updated) > new Date(objectiveDData.updated.toString()) &&
          objectiveDData.manager_rating !== objectiveDData.old_manager_rating
        ) {
          setIsRatingChanged(true)
        } else {
          setIsRatingChanged(false)
        }
      }
      setObjectiveData((prev: RevueObjective) => {
        return Object.assign(cloneDeep(prev), objectiveDData)
      })
    }
  }, [objectiveDetailData?.data?.data])

  useEffect(() => {
    if (promotion?.data?.data) {
      let promotionDData = promotion?.data?.data
      if (promotionDData.id) {
        const localData = getLocal(`promotion-${promotionDData.id}`)
        if (!localData) {
          setLocal(promotionDData, `promotion-${promotionDData.id}`)
          setInitialPromotionData((prev: Promotion) => {
            return Object.assign(cloneDeep(prev), promotionDData)
          })
        } else {
          if (promotionDData.updated && new Date(localData.updated) > new Date(promotionDData.updated)) {
            promotionDData = localData
          } else {
            setLocal(promotionDData, `promotion-${promotionDData.id}`)
            setInitialPromotionData((prev: Promotion) => {
              return Object.assign(cloneDeep(prev), promotionDData)
            })
          }
        }
      }
      setPromotionData((prev: Promotion) => {
        return Object.assign(cloneDeep(prev), promotionDData)
      })
      setIsPromoted(true)
    }
  }, [promotion.data?.data])

  useMemo(() => {
    const _isActive = isActive()
    setIsSelfRatingDisabled(isManager || !objectiveData.self_assessment_enabled)
    setIsSelfAssessmentDisabled(isManager || !objectiveData.self_assessment_enabled)
    setIsManagerRatingDisabled(!isManager || !!objectiveData.manager_rating_disabled)
    setIsManagerAssessmentDisabled(!isManager || !objectiveData.manager_assessment_enabled)
    setIsCurrentDisabled(!_isActive)
    setPromotionDisabled(!objectiveData.manager_assessment_enabled)
    setSponsor1Disabled(!objectiveData.manager_assessment_enabled)
    setSponsor2Disabled(!objectiveData.manager_assessment_enabled)
    setCraftDisabled(!objectiveData.manager_assessment_enabled)
    setMethodDisabled(!objectiveData.manager_assessment_enabled)
    setLeadershipDisabled(!objectiveData.manager_assessment_enabled)
  }, [isActive, isManager, objectiveData])

  //HANDLE LOCAL STATE CHANGES
  useEffect(() => {
    let _isObjectiveChanged = false
    const initialObjectiveDataWithoutGoals = omit(initialObjectiveData, GOALS_ID)
    const objectiveDataWithoutGoals = omit(objectiveData, GOALS_ID)
    _isObjectiveChanged = !isEqual(initialObjectiveDataWithoutGoals, objectiveDataWithoutGoals)
    const _isPromotionChanged =
      (!isPromoted && !!initialPromotionData.id) || !isEqual(initialPromotionData, promotionData)

    if (!_isObjectiveChanged) {
      if (objectiveData.goals.length > initialObjectiveData.goals.length) {
        _isObjectiveChanged = true
      } else if (objectiveData.goals.length === initialObjectiveData.goals.length) {
        _isObjectiveChanged = !isEqual(initialObjectiveData.goals, objectiveData.goals)
      } else if (objectiveData.goals.length < initialObjectiveData.goals.length) {
        //deletion has happened
        //if there was a change in other goals then set isObjectiveChanged to true
        _isObjectiveChanged = !objectiveData.goals.every((goal) => {
          const initGoal = initialObjectiveData.goals.find((iGoal) => iGoal.id === goal.id)
          if (isEqual(initGoal, goal)) {
            return true
          }
          return false
        })
      }
    }
    setIsObjectiveChanged(_isObjectiveChanged)
    setIsPromotionChanged(_isPromotionChanged)

    if (objectiveData.goals?.length >= 10) {
      setIsAddGoalDisabled(true)
      setErrorMsg('You cannot add more than 10 goals')
    } else {
      setIsAddGoalDisabled(false)
      setErrorMsg(null)
    }

    if (_isObjectiveChanged && objectiveData.id) {
      setLocal({ ...objectiveData, updated: new Date() }, `objective-${objectiveData.id}`)
    }
    if (_isPromotionChanged && promotionData.id) {
      setLocal({ ...promotionData, updated: new Date() }, `promotion-${promotionData.id}`)
    }
  }, [initialObjectiveData, initialPromotionData, isPromoted, objectiveData, promotionData])

  //VALIDATE OBJECTIVE DATA
  const handleObjectiveChange = useCallback(
    (value: string | RevueObjectiveGoal[], _id?: string, _isPromoted?: boolean) => {
      if (!_id) {
        log.warn('No id')
        return
      }

      const validationResult = validationRules[_id]?.validate(value, _isPromoted)

      if (_id === CURRENT_ID) {
        setIsCurrentInvalid(validationResult.isInvalid)
        setCurrentErrorMsg(validationResult.errorMsg)
      } else if (_id === GOALS_ID) {
        setIsGoalsInvalid(validationResult.isInvalid)
        setErrorMsg(validationResult.errorMsg)
      } else if (_id === SELF_RATING_ID) {
        setIsSelfRatingInvalid(validationResult.isInvalid)
      } else if (_id === SELF_ASSESSMENT_ID) {
        setIsSelfAssessmentInvalid(validationResult.isInvalid)
      } else if (_id === MANAGER_RATING_ID) {
        setIsManagerRatingInvalid(validationResult.isInvalid)
      } else if (_id === MANAGER_ASSESSMENT_ID) {
        setIsManagerAssessmentInvalid(validationResult.isInvalid)
      }

      handleObjectiveStateUpdate(value, _id)
    },
    []
  )

  const isPromotionDataInvalid = useCallback(() => {
    const _isSponsor1Invalid = !isSponsor1Disabled
      ? validationRules[SPONSOR1_ID]?.validate(promotionData.rel.sponsor1).isInvalid
      : false
    const _isSponsor2Invalid = !isSponsor2Disabled
      ? validationRules[SPONSOR2_ID]?.validate(promotionData.rel.sponsor2).isInvalid
      : false
    const _isCraftInvalid = !isCraftDisabled
      ? validationRules[CRAFT_ID]?.validate(promotionData.craft).isInvalid
      : false
    const _isMethodInvalid = !isMethodDisabled
      ? validationRules[METHOD_ID]?.validate(promotionData.method).isInvalid
      : false
    const _isLeadershipInvalid = !isLeadershipDisabled
      ? validationRules[LEADERSHIP_ID]?.validate(promotionData.leadership).isInvalid
      : false
    return _isSponsor1Invalid || _isSponsor2Invalid || _isCraftInvalid || _isMethodInvalid || _isLeadershipInvalid
  }, [
    isCraftDisabled,
    isLeadershipDisabled,
    isMethodDisabled,
    isSponsor1Disabled,
    isSponsor2Disabled,
    promotionData.craft,
    promotionData.leadership,
    promotionData.method,
    promotionData.rel.sponsor1,
    promotionData.rel.sponsor2,
  ])

  const isObjectiveDataInvalid = useCallback(() => {
    const _isCurrentInvalid = !isCurrentDisabled
      ? validationRules[CURRENT_ID]?.validate(objectiveData.current).isInvalid
      : false
    const _isSelfRatingInvalid = !isSelfRatingDisabled
      ? validationRules[SELF_RATING_ID]?.validate(objectiveData.self_rating).isInvalid
      : false
    const _isSelfAssessmentInvalid = !isSelfAssessmentDisabled
      ? validationRules[SELF_ASSESSMENT_ID]?.validate(objectiveData.self_assessment).isInvalid
      : false
    const _isManagerRatingInvalid = !isManagerRatingDisabled
      ? validationRules[MANAGER_RATING_ID]?.validate(objectiveData.manager_rating, isPromoted).isInvalid
      : false
    const _isManagerAssessmentInvalid = !isManagerAssessmentDisabled
      ? validationRules[MANAGER_ASSESSMENT_ID]?.validate(objectiveData.manager_assessment).isInvalid
      : false
    const _isGoalsInvalid = validationRules[GOALS_ID]?.validate(objectiveData.goals).isInvalid
    return (
      _isCurrentInvalid ||
      _isSelfRatingInvalid ||
      _isSelfAssessmentInvalid ||
      _isManagerRatingInvalid ||
      _isManagerAssessmentInvalid ||
      _isGoalsInvalid
    )
  }, [
    isCurrentDisabled,
    objectiveData,
    isSelfRatingDisabled,
    isSelfAssessmentDisabled,
    isManagerRatingDisabled,
    isPromoted,
    isManagerAssessmentDisabled,
  ])

  //VALIDATE PROMOTION DATA
  const handlePromotionChange = useCallback((value: string, _id?: string) => {
    if (!_id) {
      log.warn('No id')
      return
    }
    if (_id === SPONSOR1_ID) {
      setSponsor1Invalid(validationRules[_id]?.validate(value).isInvalid)
    } else if (_id === SPONSOR2_ID) {
      setSponsor2Invalid(validationRules[_id]?.validate(value).isInvalid)
    } else if (_id === CRAFT_ID) {
      setCraftInvalid(validationRules[_id]?.validate(value).isInvalid)
    } else if (_id === METHOD_ID) {
      setMethodInvalid(validationRules[_id]?.validate(value).isInvalid)
    } else if (_id === LEADERSHIP_ID) {
      setLeadershipInvalid(validationRules[_id]?.validate(value).isInvalid)
    }
    handlePromotionStateUpdate(value, _id)
  }, [])

  //SET OBJECTIVE DATA VALIDITY
  useEffect(() => {
    setIsRevueInvalid(
      (isPromoted && (isPromotionDataInvalid() || isManagerRatingInvalid)) || (!isPromoted && isObjectiveDataInvalid())
    )
  }, [isPromoted, isPromotionDataInvalid, isObjectiveDataInvalid, isManagerRatingInvalid])

  const handleObjectiveStateUpdate = (value: string | RevueObjectiveGoal[], _id?: string) => {
    setObjectiveData((prev: RevueObjective) => {
      const prevCopy = cloneDeep(prev)
      prevCopy[_id as keyof RevueObjective] = value
      return prevCopy
    })
  }

  const handlePromotionStateUpdate = (value: string, _id?: string) => {
    setPromotionData((prev: Promotion) => {
      const prevCopy = cloneDeep(prev)
      if (_id && _id in prevCopy.rel) {
        prevCopy.rel[_id as string] = value
      } else {
        prevCopy[_id as keyof Promotion] = value
      }
      return prevCopy
    })
  }

  const handlePromotionCheckboxChange = (checked: boolean) => {
    setIsPromoted(checked)
    handleObjectiveChange(objectiveData.manager_rating, MANAGER_RATING_ID, checked)
    if (checked) {
      handleObjectiveChange(initialObjectiveData.manager_assessment, MANAGER_ASSESSMENT_ID)
    } else {
      setPromotionData(initialPromotionData)
      if (
        objectiveData.manager_rating === NAOption.value &&
        (!objectiveData?.manager_assessment || objectiveData?.manager_assessment.trim().length === 0)
      ) {
        handleObjectiveChange(NAOption.value, MANAGER_ASSESSMENT_ID, checked)
      } else {
        handleObjectiveChange(initialObjectiveData.manager_assessment, MANAGER_ASSESSMENT_ID)
      }
    }
  }

  const handleBlur = useCallback(
    (value: string, _id?: string) => {
      handleObjectiveChange(value ?? '', _id)
    },
    [handleObjectiveChange]
  )

  const createGoalsforSave = (oData: RevueObjective) => {
    return oData.goals.map((goal: RevueObjectiveGoal) => {
      const newGoal = new RevueObjectiveGoal(goal.id, goal.rel.objective)
      newGoal.title = goal.title
      newGoal.manager_note = goal.manager_note
      newGoal.description = goal.description
      newGoal.progress = goal.progress
      newGoal.created = goal.created
      newGoal.updated = goal.updated
      return newGoal
    })
  }

  const saveObjective = useCallback(async () => {
    const updatePromotionData = (data: Promotion | null) => {
      if (!data) return
      setInitialPromotionData((prev: Promotion) => Object.assign(cloneDeep(prev), data))
      setPromotionData((prev: Promotion) => Object.assign(cloneDeep(prev), data))
    }

    const updateObjectiveData = (data: RevueObjective | null | undefined) => {
      if (!data) return
      setInitialObjectiveData((prev: RevueObjective) => Object.assign(cloneDeep(prev), data))
      setObjectiveData((prev: RevueObjective) => Object.assign(cloneDeep(prev), data))
    }

    let objectiveSaveNeeded
    let objectiveSaveWasSuccessful = false
    const updateObjectiveOnServer = () => {
      objectiveData.goals = createGoalsforSave(objectiveData)
      return updateRevueObjective.mutateAsync(objectiveData, {
        onSuccess: () => {
          objectiveSaveWasSuccessful = true
          toast.success('Objective saved successfuly.')
        },
        onError: (error) => {
          toast.error(error as string)
        },
      })
    }
    if (isActive()) {
      if (!isPromoted) {
        objectiveSaveNeeded = isObjectiveChanged
        const promotionRemovalNeeded = !!promotionData.id
        let objectiveSaveResp: Response<RevueObjective> | null = null
        let promotionRemoveWasSuccessful = false

        const removePromotionFromServer = () => {
          return removePromotion.mutateAsync(promotionData.id, {
            onSuccess: () => {
              promotionRemoveWasSuccessful = true
              toast.success('Promotion revoked successfuly.')
            },
            onError: (error) => {
              toast.error(error as string)
            },
          })
        }
        if (objectiveSaveNeeded && promotionRemovalNeeded) {
          const [resp] = await Promise.all([updateObjectiveOnServer(), removePromotionFromServer()])
          objectiveSaveResp = resp
        } else if (objectiveSaveNeeded && !promotionRemovalNeeded) {
          objectiveSaveResp = await updateObjectiveOnServer()
        } else if (!objectiveSaveNeeded && promotionRemovalNeeded) {
          await removePromotionFromServer()
        }

        if (
          objectiveSaveNeeded &&
          promotionRemovalNeeded &&
          objectiveSaveWasSuccessful &&
          promotionRemoveWasSuccessful
        ) {
          if (objectiveSaveResp?.data) {
            updateObjectiveData(objectiveSaveResp?.data)
            updatePromotionData(new Promotion(''))
          }
        } else if (objectiveSaveNeeded && !promotionRemovalNeeded && objectiveSaveWasSuccessful) {
          updateObjectiveData(objectiveSaveResp?.data)
        } else if (!objectiveSaveNeeded && promotionRemovalNeeded && promotionRemoveWasSuccessful) {
          updatePromotionData(new Promotion(''))
        }
      } else {
        objectiveSaveNeeded =
          initialObjectiveData.manager_assessment !== '' ||
          initialObjectiveData.manager_rating !== objectiveData.manager_rating
        const promotionUpdateNeeded = !!promotionData.id
        let promotionAddOrUpdateWasSuccessful = false
        let objectiveSaveResp: Response<RevueObjective> | null = null
        let promotionSaveResp: Response<Promotion> | null = null
        const promotionDataClone = cloneDeep(promotionData)
        promotionDataClone.rel.objective = objectiveData.id
        promotionDataClone.rel.source = objectiveData.source
        promotionDataClone.rel.target = objectiveData.target

        const onSuccess = () => {
          promotionAddOrUpdateWasSuccessful = true
          toast.success('Promotion saved successfuly.')
        }
        const onError = (error: unknown) => {
          toast.error(error as string)
        }

        if (objectiveSaveNeeded) {
          const objectiveDataClone = cloneDeep(objectiveData)
          objectiveDataClone.manager_assessment = ''
          objectiveDataClone.goals = createGoalsforSave(objectiveDataClone)
          objectiveSaveResp = await updateRevueObjective.mutateAsync(objectiveDataClone, {
            onSuccess: () => {
              objectiveSaveWasSuccessful = true
              toast.success('Objective saved successfuly.')
            },
            onError: (error) => {
              toast.error(error as string)
            },
          })
        }

        //since the promotion checkbox is checked we need to add or update the promotion
        if (promotionUpdateNeeded) {
          promotionSaveResp = await updatePromotion.mutateAsync(promotionDataClone, {
            onSuccess,
            onError,
          })
        } else {
          promotionSaveResp = await addPromotion.mutateAsync(promotionDataClone, {
            onSuccess,
            onError,
          })
        }

        if (objectiveSaveNeeded && objectiveSaveWasSuccessful && promotionAddOrUpdateWasSuccessful) {
          if (objectiveSaveResp?.data) {
            updateObjectiveData(objectiveSaveResp?.data)
            updatePromotionData(promotionSaveResp?.data)
          }
        } else if (!objectiveSaveNeeded) {
          updatePromotionData(promotionSaveResp?.data)
        }
      }
    } else if (isObjectiveChanged) {
      const objectiveSaveResp = await updateObjectiveOnServer()
      if (objectiveSaveResp?.data) {
        updateObjectiveData(objectiveSaveResp?.data)
      }
    }
  }, [
    addPromotion,
    initialObjectiveData.manager_assessment,
    initialObjectiveData.manager_rating,
    isActive,
    isObjectiveChanged,
    isPromoted,
    objectiveData,
    promotionData,
    removePromotion,
    updatePromotion,
    updateRevueObjective,
  ])

  unstable_usePrompt({ when: isObjectiveChanged || isPromotionChanged, message: 'Changes you made may not be saved.' })

  useBeforeUnload(
    useCallback(
      (event: BeforeUnloadEvent) => {
        if (isObjectiveChanged || isPromotionChanged) event.returnValue = ''
      },
      [isObjectiveChanged, isPromotionChanged]
    )
  )

  const checkIfNotSaved = useCallback(() => {
    if ((isObjectiveChanged || isPromotionChanged) && !window.confirm('Changes you made may not be saved.')) return
    handleClose()
    deleteLocal(`objective-${objectiveData.id}`)
  }, [handleClose, isObjectiveChanged, isPromotionChanged])

  const handleModalClose = (confirmed: boolean) => {
    if (confirmed) {
      const remove = async () => {
        const res = await removeRevueObjective.mutateAsync(id)
        if (res.data) {
          setShowConfirm(false)
          handleClose(true)
        }
      }
      remove()
    } else {
      setShowConfirm(false)
    }
  }

  const renderHtml = (content: any) => {
    return <div dangerouslySetInnerHTML={{ __html: content }} className={Style.hrefcontent}></div>
  }

  const handleAddClick = () => {
    const newGoal = new RevueObjectiveGoal('new' + nanoid(), objectiveData.id)
    newGoal.title = objectiveData.title
    const updatedGoals = [newGoal, ...objectiveData.goals]
    handleObjectiveChange(updatedGoals, GOALS_ID)
  }

  const handleGoalDetailsChange = (goal: RevueObjectiveGoal | null, index: number) => {
    const goalsCopy = cloneDeep(objectiveData.goals)
    if (goal === null) {
      if (index >= 0 && index < goalsCopy.length) goalsCopy.splice(index, 1)
    } else {
      goalsCopy[index] = goal
    }

    handleObjectiveChange(goalsCopy, GOALS_ID)
  }

  return (
    <div className={Style.wrapper} data-test={`${dataTest}.wrapper`}>
      <div className={`${Style.leftSection} w-[40%]`}>
        <h1 className={Style.title} data-test={`${dataTest}.title`}>
          {getTitle(objectiveData, isManager)}
        </h1>

        {RevueObjectiveTitle.PERSONAL_CHARGEABILITY === objectiveData.title.trim() && (
          <div className={`${Style.current} flex`}>
            <Input
              customClasses="w-full"
              data-test={`${dataTest}.current`}
              disabled={isCurrentDisabled}
              help={isCurrentInvalid ? currentErrorMsg ?? '' : ''}
              id={CURRENT_ID}
              invalid={isCurrentInvalid}
              label={'Current Level'}
              value={objectiveData?.current ?? ''}
              onBlur={handleBlur}
              onChange={handleObjectiveChange}
            ></Input>
          </div>
        )}
        <div className={`${Style.detailsSec} py-[20px] flex`}>
          {RevueObjectiveTitle.PERSONAL_CHARGEABILITY === objectiveData.title.trim() && (
            <div className={`${Style.target} flex`}>
              <Input
                customClasses="min-w[60px]"
                data-test={`${dataTest}.target`}
                disabled={true}
                id="kpi"
                label={'Target'}
                value={objectiveData?.kpi}
                onChange={handleObjectiveChange}
              ></Input>
            </div>
          )}
          <div className={`${Style.days} flex`}>
            <div>
              <h4 className="text-[20px]" data-test={`${dataTest}.days`}>
                {calcDaysRemaining()}
              </h4>
              <p>Days Remaining</p>
            </div>
          </div>
          <div className={`${Style.updated} flex`}>
            <div>
              <h4 className="text-[14px]" data-test={`${dataTest}.updated-date`}>
                {objectiveData?.updated && DateTime.fromJSDate(new Date(objectiveData?.updated)).toFormat('dd MMMM y')}
              </h4>
              <h4 className="text-[14px]" data-test={`${dataTest}.updated-time`}>
                {objectiveData?.updated && DateTime.fromJSDate(new Date(objectiveData?.updated)).toFormat('HH:mm:ss')}
              </h4>
              <p>Last Updated</p>
            </div>
          </div>
        </div>
        <div className="mt-[20px] mb-[40px]" data-test={`${dataTest}.description`}>
          {renderHtml(getDescription(objectiveData, isManager, true))}
        </div>
        <div className="flex mb-[20px] items-end gap-[8px]">
          <h3>Self Assessment</h3>
          {!showSelfTooltip && (
            <span onClick={() => setShowSelfTooltip(true)}>
              <Icon data-test={`tooltip.close-icon`} name={IconName.InformationCircle} />
            </span>
          )}
          {showSelfTooltip && (
            <span onClick={() => setShowSelfTooltip(false)}>
              <Icon data-test={`tooltip.close-icon`} name={IconName.XMark} />
            </span>
          )}
        </div>
        {showSelfTooltip && managerTooltipHTML}
        <Combobox
          data-test={`${dataTest}.self-rating`}
          disabled={isSelfRatingDisabled}
          help={isSelfRatingInvalid ? 'Self Rating is required' : ''}
          invalid={isSelfRatingInvalid}
          isSearchable={false}
          options={ratingOptions}
          placeholder={'Self Rating'}
          value={
            {
              value: objectiveData.self_rating ? objectiveData.self_rating : null,
              label: objectiveData.self_rating ? objectiveData.self_rating : 'Self Rating',
            } as Option
          }
          onBlur={() => handleObjectiveChange(objectiveData.self_rating, SELF_RATING_ID)}
          onChange={(selectedValue) => {
            if (
              (selectedValue as Option).value === NAOption.value &&
              (!objectiveData?.self_assessment || objectiveData?.self_assessment.trim().length === 0)
            ) {
              handleObjectiveChange(NAOption.value, SELF_ASSESSMENT_ID)
            }
            handleObjectiveChange((selectedValue as Option).value ?? '', SELF_RATING_ID)
          }}
        />
        <Textarea
          data-test={`${dataTest}.self-assessment`}
          disabled={isSelfAssessmentDisabled}
          help={isSelfAssessmentInvalid ? 'Self Assessment is required' : ''}
          id={SELF_ASSESSMENT_ID}
          label={''}
          maxSymbols={5000}
          rows={12}
          valid={!isSelfAssessmentInvalid}
          value={objectiveData?.self_assessment}
          onBlur={handleObjectiveChange}
          onChange={handleObjectiveChange}
        ></Textarea>
        {((objectiveData.manager_assessment_visible && !isManager) || isManager) && (
          <>
            <div className="flex mb-[20px] items-end gap-[8px]">
              <h3>Manager Assessment</h3>
              {!showManagerTooltip && (
                <span onClick={() => setShowManagerTooltip(true)}>
                  <Icon data-test={`tooltip.close-icon`} name={IconName.InformationCircle} />
                </span>
              )}
              {showManagerTooltip && (
                <span onClick={() => setShowManagerTooltip(false)}>
                  <Icon data-test={`tooltip.close-icon`} name={IconName.XMark} />
                </span>
              )}
            </div>
            {showManagerTooltip && managerTooltipHTML}
            {isRatingChanged && (
              <h4>
                Your submitted performance rating has been changed. Please review your assessment details and align it
                with the final rating.
              </h4>
            )}
            <Combobox
              data-test={`${dataTest}.manager-rating`}
              disabled={isManagerRatingDisabled}
              help={isManagerRatingInvalid ? 'Manager Rating is required' : ''}
              invalid={isManagerRatingInvalid}
              isSearchable={false}
              options={ratingOptions}
              placeholder={'Manager Rating'}
              value={
                {
                  value: objectiveData.manager_rating ? objectiveData.manager_rating : null,
                  label: objectiveData.manager_rating ? objectiveData.manager_rating : 'Manager Rating',
                } as Option
              }
              onBlur={() => handleObjectiveChange(objectiveData.manager_rating, MANAGER_RATING_ID, isPromoted)}
              onChange={(selectedValue) => {
                if (
                  !isPromoted &&
                  (selectedValue as Option).value === NAOption.value &&
                  (!objectiveData?.manager_assessment || objectiveData?.manager_assessment.trim().length === 0)
                ) {
                  handleObjectiveChange(NAOption.value, MANAGER_ASSESSMENT_ID, isPromoted)
                }
                handleObjectiveChange((selectedValue as Option).value ?? '', MANAGER_RATING_ID, isPromoted)
              }}
            />
            {isManager && objectiveData.title === RevueObjectiveTitle.SUMMARY_OF_ACCOMPLISHMENTS && (
              <div className="flex justify-start gap-[8px]">
                <Checkbox
                  data-test={`${dataTest}.promotion`}
                  isDisabled={isPromotionDisabled}
                  label={'Promotion nomination'}
                  value={isPromoted}
                  onChange={handlePromotionCheckboxChange}
                ></Checkbox>
                {!showPromotionTooltip && (
                  <span onClick={() => setShowPromotionTooltip(true)}>
                    <Icon data-test={`tooltip.close-icon`} name={IconName.InformationCircle} />
                  </span>
                )}
                {showPromotionTooltip && (
                  <span onClick={() => setShowPromotionTooltip(false)}>
                    <Icon data-test={`tooltip.close-icon`} name={IconName.XMark} />
                  </span>
                )}
              </div>
            )}
            {showPromotionTooltip && promotionTooltipHTML}
            {!isPromoted && (
              <Textarea
                data-test={`${dataTest}.manager-assessment`}
                disabled={isManagerAssessmentDisabled}
                help={isManagerAssessmentInvalid ? 'Manager Assessment is required' : ''}
                id={MANAGER_ASSESSMENT_ID}
                label={''}
                maxSymbols={5000}
                rows={12}
                valid={!isManagerAssessmentInvalid}
                value={objectiveData?.manager_assessment}
                onBlur={handleObjectiveChange}
                onChange={handleObjectiveChange}
              ></Textarea>
            )}
            {isPromoted && (
              <>
                <Combobox
                  customClasses={Style.userName}
                  data-test={`${dataTest}.sponsor1`}
                  disabled={isSponsor1Disabled}
                  help={isSponsor1Invalid ? 'Sponsor 1 is required' : ''}
                  invalid={isSponsor1Invalid}
                  label="Sponsor 1"
                  options={resourceOptions.filter((val) => val.value !== promotionData.rel.sponsor2)}
                  value={resourceOptions.find((val) => val.value === promotionData.rel.sponsor1) || null}
                  onChange={(value) => {
                    const resourceOption = value as Option
                    handlePromotionChange(resourceOption.value ?? '', SPONSOR1_ID)
                  }}
                />
                <Combobox
                  customClasses={Style.userName}
                  data-test={`${dataTest}.sponsor2`}
                  disabled={isSponsor2Disabled}
                  help={isSponsor2Invalid ? 'Sponsor 2 is required' : ''}
                  invalid={isSponsor2Invalid}
                  label="Sponsor 2"
                  options={resourceOptions.filter((val) => val.value !== promotionData.rel.sponsor1)}
                  value={resourceOptions.find((val) => val.value === promotionData.rel.sponsor2) || null}
                  onChange={(value) => {
                    const resourceOption = value as Option
                    handlePromotionChange(resourceOption.value ?? '', SPONSOR2_ID)
                  }}
                />
                <Textarea
                  data-test={`${dataTest}.craft`}
                  disabled={isCraftDisabled}
                  help={isCraftInvalid ? 'The Craft is required' : ''}
                  id={CRAFT_ID}
                  label={'The Craft - Core skills in area of expertise'}
                  valid={!isCraftInvalid}
                  value={promotionData?.craft}
                  onBlur={handlePromotionChange}
                  onChange={handlePromotionChange}
                ></Textarea>
                <Textarea
                  data-test={`${dataTest}.method`}
                  disabled={isMethodDisabled}
                  help={isMethodInvalid ? 'The Method is required' : ''}
                  id={METHOD_ID}
                  label={'The Method - Consulting skillset'}
                  valid={!isMethodInvalid}
                  value={promotionData?.method}
                  onBlur={handlePromotionChange}
                  onChange={handlePromotionChange}
                ></Textarea>
                <Textarea
                  data-test={`${dataTest}.leadership`}
                  disabled={isLeadershipDisabled}
                  help={isLeadershipInvalid ? 'Leadership and Impact is required' : ''}
                  id={LEADERSHIP_ID}
                  label={'Leadership and Impact - Scope of business results'}
                  valid={!isLeadershipInvalid}
                  value={promotionData?.leadership}
                  onBlur={handlePromotionChange}
                  onChange={handlePromotionChange}
                ></Textarea>
              </>
            )}
          </>
        )}
      </div>
      <div className={`${Style.rightSection} w-[60%]`}>
        <div className={Style.topSection}>
          <Button
            customClasses={cx(Style.save, { [Style.disabled]: updateRevueObjective.isLoading })}
            data-test={`${dataTest}.save-button`}
            fill={ButtonFill.Solid}
            icon={IconName.Check}
            disabled={
              updateRevueObjective.isLoading ||
              updatePromotion.isLoading ||
              addPromotion.isLoading ||
              removePromotion.isLoading ||
              isRevueInvalid ||
              !(isObjectiveChanged || isPromotionChanged)
            }
            onClick={saveObjective}
          >
            Save Objective
          </Button>
          {false && isManager && (
            <button className={`${Style.button} ${Style.delete} mb-[20px]`} onClick={() => setShowConfirm(true)}>
              <Icon data-test={`${dataTest}.delete-button`} name={IconName.Trash}></Icon> Delete Objective
            </button>
          )}
          <button className={`${Style.button} mb-[20px]`} onClick={checkIfNotSaved}>
            <Icon data-test={`${dataTest}.back-button`} name={IconName.ArrowLeft}></Icon> Back to Dashboard
          </button>
        </div>
        <hr className={'my-[20px] w-[100%]'}></hr>
        <div className={'flex justify-end items-center gap-[10px]'}>
          <h1 className={`${Style.heading}`}>
            <span> Goals</span>
            <span data-test={`${dataTest}.goals-count`}> {`(${objectiveData.goals?.length ?? 0})`}</span>
          </h1>
          {!isNil(errorMsg) && (
            <span className={Style.error} data-test={`${dataTest}.error`}>
              {errorMsg}
            </span>
          )}
          {!isManager && (
            <Button
              customClasses={`order-2`}
              data-test={`${dataTest}.add-goal-button`}
              icon={IconName.PlusCircle}
              size={ButtonSize.Default}
              disabled={
                isManager ||
                isGoalsInvalid ||
                isAddGoalDisabled ||
                new Date(initialObjectiveData.created + '').getFullYear() < getCurrentYear()
              }
              onClick={handleAddClick}
            >
              Add Goal
            </Button>
          )}
        </div>
        <hr className={'my-[20px] w-[100%]'}></hr>
        <PerformanceObjectiveGoalList
          goals={objectiveData.goals}
          isGoalsInvalid={isGoalsInvalid}
          isManager={isManager}
          onGoalDetailsChange={handleGoalDetailsChange}
        />
      </div>
      <Modal data-test={`${dataTest}.confirmation-modal`} isShown={showConfirm} onHide={() => setShowConfirm(false)}>
        <ConfirmationDialog data-test={`${dataTest}.confirmation-dialog`} onConfirm={handleModalClose} />
      </Modal>
      {(objectiveDetailData.isLoading ||
        updateRevueObjective.isLoading ||
        (isManager && promotion.isLoading) ||
        addPromotion.isLoading ||
        updatePromotion.isLoading ||
        removePromotion.isLoading) && (
        <div className={cx(Style.blockingOpaqueBlock, Style.loadingBlock)}>
          <div className={Style.loadingContainer}>
            <Loading data-test={`${dataTest}.loading`} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PerformanceObjectiveDetail
export type { Props }
