export enum Skill {
  'Accountibility' = 'Accountibility',
  'ActiveListening' = 'Active Listening',
  'Adaptivity' = 'Adaptivity',
  'Agility' = 'Agility',
  'Aligned' = 'Aligned',
  'Ambitious' = 'Ambitious',
  'Approachable' = 'Approachable',
  'BusinessAccumen' = 'Business Accumen',
  'Careful' = 'Careful',
  'ClientFocus' = 'Client Focus',
  'Coaching&Mentoring	' = 'Coaching & Mentoring',
  'Collaborative' = 'Collaborative',
  'Communication' = 'Communication',
  'Confident' = 'Confident',
  'Consistency' = 'Consistency',
  'ConsultingMindset' = 'Consulting Mindset',
  'ContinuousLearning' = 'Continuous learning',
  'Creative' = 'Creative',
  'CriticalThinking' = 'Critical Thinking',
  'Curiousity' = 'Curiousity',
  'DecisionMaking' = 'Decision Making',
  'Delegation' = 'Delegation',
  'DetailOriented' = 'Detail oriented',
  'Discipline' = 'Discipline',
  'Effective' = 'Effective',
  'Empathetic' = 'Empathetic',
  'Engaged' = 'Engaged',
  'Flexible' = 'Flexible',
  'Go-Getter' = 'Go-Getter',
  'GoalOriented' = 'Goal Oriented',
  'Innovation' = 'Innovation',
  'Inspired/Motivated' = 'Inspired/Motivated',
  'KnowledgeSharing' = 'Knowledge sharing',
  'Loyalty' = 'Loyalty',
  'Negotiation' = 'Negotiation',
  'Networking' = 'Networking',
  'Observation' = 'Observation',
  'OpennessToFeedback' = 'Openness to feedback',
  'OutOfBoxThinking' = 'Out of box thinking',
  'Ownership/Autonomy' = 'Ownership/Autonomy',
  'Participative' = 'Participative',
  'PeopleOriented' = 'People Oriented',
  'PositiveAttitude' = 'Positive attitude',
  'Prioritization' = 'Prioritization',
  'Proactive' = 'Proactive',
  'ProblemSolving' = 'Problem Solving',
  'Punctuality' = 'Punctuality',
  'Pursuasive' = 'Pursuasive',
  'Reflectivity' = 'Reflectivity',
  'Reliablity' = 'Reliablity',
  'Resilience' = 'Resilience',
  'Respect' = 'Respect',
  'Responsible' = 'Responsible',
  'Responsive' = 'Responsive',
  'Supportive' = 'Supportive',
  'TeamManagement' = 'Team Management',
  'TeamPlayer' = 'Team Player',
  'TechnicalExpertise' = 'Technical Expertise',
  'Trustworthiness' = 'Trustworthiness',
}
