import Style from './style.module.scss'

export const columnDef = [
  {
    headerName: 'First Name',
    field: 'tFname',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.tFname,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Last Name',
    field: 'tLname',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.tLname,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Employee ID',
    field: 'target',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.target,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'Email',
    field: 'temail',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.temail,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'People Manager First Name',
    field: 'sFname',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.sFname,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'People Manager Last Name',
    field: 'sLname',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.sLname,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'People Manager Employee ID',
    field: 'source',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.source,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'People Manager Email',
    field: 'semail',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.semail,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'Objective Name',
    field: 'title',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.title,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Self Rating',
    field: 'self_rating',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.self_rating,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Self Assessment text',
    field: 'self_assessment',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.self_assessment,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Manager Rating',
    field: 'manager_rating',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.manager_rating,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Manager Assessment text',
    field: 'manager_assessment',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.manager_assessment,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Business Team',
    field: 'businessTeam',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.businessTeam,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'Practice',
    field: 'practice',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.practice,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'Office City',
    field: 'officeCity',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.officeCity,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'Leader',
    field: 'leader',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.leader,
    headerClass: Style.customHeader,
    minWidth: 100,
  },
  {
    headerName: 'Promotion',
    field: 'promotion',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.promotion,
    headerClass: Style.customHeader,
    minWidth: 150,
  },
  {
    headerName: 'Sponsor 1',
    field: 'sponsor1',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.sponsor1,
    headerClass: Style.customHeader,
    minWidth: 150,
  },
  {
    headerName: 'Sponsor 2',
    field: 'sponsor2',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.sponsor2,
    headerClass: Style.customHeader,
    minWidth: 150,
  },
  {
    headerName: 'The Craft',
    field: 'craft',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.craft,
    headerClass: Style.customHeader,
    minWidth: 150,
  },
  {
    headerName: 'The Method',
    field: 'method',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.method,
    headerClass: Style.customHeader,
    minWidth: 150,
  },
  {
    headerName: 'Leadership and Impact',
    field: 'leadership',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.leadership,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Self Status',
    field: 'selfStatus',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.selfStatus,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Self Overall Status',
    field: 'selfOverall',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.selfOverall,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Manager Status',
    field: 'managerStatus',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.managerStatus,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Manager Overall Status',
    field: 'managerOverall',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.managerOverall,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Goal 1',
    field: 'goal1',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.goal1,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Goal 2',
    field: 'goal2',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.goal2,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Other Goals',
    field: 'goal3',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.goal3,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
]
